import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/kenneth/Repos/typebrigade/typebrigade.com/src/components/TemplateBlogPost.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bruno Maag, the London-based co-founder of the renowned type design studio `}<a parentName="p" {...{
        "href": "http://daltonmaag.com/"
      }}>{`Dalton Maag`}</a>{` is traveling to speak at `}<a parentName="p" {...{
        "href": "http://bruno-maag-talks-type.eventbrite.com/"
      }}>{`a very special edition of Type Brigade`}</a>{`, March 26`}<sup>{`th`}</sup>{` at Emily Carr University of Art + Design.`}</p>
    <p>{`The event is completely free to attend as long you `}<a parentName="p" {...{
        "href": "http://bruno-maag-talks-type.eventbrite.com/"
      }}>{`register soon`}</a>{`.`}</p>
    <figure {...{
      "className": "figure figure--breakout"
    }}>
      <span parentName="figure" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0554acda82f64534682bc1d59efb4e73/3acf0/blog-bruno-maag-at-type-brigade-1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAIABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAT/xAAXAQADAQAAAAAAAAAAAAAAAAABAgME/9oADAMBAAIQAxAAAAGmUrjpBX//xAAZEAADAAMAAAAAAAAAAAAAAAABAhEAAxL/2gAIAQEAAQUCsIXaMW8//8QAFREBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQMBAT8BSf/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAAxARESMv/aAAgBAQAGPwJWdEaZ/8QAGBABAAMBAAAAAAAAAAAAAAAAAQARMSH/2gAIAQEAAT8heS3FxxxeU5G2Oez/2gAMAwEAAgADAAAAEHfP/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAIf/aAAgBAwEBPxAhzLW7/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EE2L/8QAGxABAAICAwAAAAAAAAAAAAAAAQARITFBUYH/2gAIAQEAAT8QehuFcEQhgKMGlDjyVeFh6Z//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blog bruno maag at type brigade 1",
            "title": "blog bruno maag at type brigade 1",
            "src": "/static/0554acda82f64534682bc1d59efb4e73/b17f8/blog-bruno-maag-at-type-brigade-1.jpg",
            "srcSet": ["/static/0554acda82f64534682bc1d59efb4e73/066f9/blog-bruno-maag-at-type-brigade-1.jpg 400w", "/static/0554acda82f64534682bc1d59efb4e73/4b190/blog-bruno-maag-at-type-brigade-1.jpg 800w", "/static/0554acda82f64534682bc1d59efb4e73/b17f8/blog-bruno-maag-at-type-brigade-1.jpg 1600w", "/static/0554acda82f64534682bc1d59efb4e73/3acf0/blog-bruno-maag-at-type-brigade-1.jpg 2000w"],
            "sizes": "(max-width: 1600px) 100vw, 1600px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>
      <figcaption parentName="figure">{`Bruno Maag, left, and the Dalton Maag team.`}</figcaption>
    </figure>
    <p>{`Type Brigade is `}<a parentName="p" {...{
        "href": "http://meetup.com/typebrigade"
      }}>{`Vancouver’s type design and typography meetup`}</a>{`, where we host speakers every other month.`}</p>
    <h2>{`Bruno Maag Talks Type`}</h2>
    <p>{`Since 1991, Dalton Maag has worked closely with clients including BMW, Intel, Nokia, and many more to develop custom typefaces for complex situations. They are now one of the largest independent type design studios in Europe. They also publish a wide range of typefaces of their own, like `}<a parentName="p" {...{
        "href": "https://www.daltonmaag.com/library/effra"
      }}>{`Effra`}</a>{`, `}<a parentName="p" {...{
        "href": "https://www.daltonmaag.com/library/prometo"
      }}>{`Prometo`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.daltonmaag.com/library/grueber"
      }}>{`Grueber`}</a>{`.`}</p>
    <p>{`We are incredibly lucky to have Bruno Maag coming all the way from London to share his wealth of type, design, and business experience with us.`}</p>
    <p>{`See you on March 26`}<sup>{`th`}</sup>{` for `}<a parentName="p" {...{
        "href": "http://bruno-maag-talks-type.eventbrite.com/"
      }}>{`Bruno Maag Talks Type`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      